.selectedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
}
.selectedOption {
    padding: 8px;
    background: #F4F4F4;
    padding-right: 0px;
    margin: 0px 8px 8px 0px;
    border-radius: 2px;
    font-size: 12px;
    font-family: Roboto;
    line-height: 14px;
    font-style: normal;
    font-weight: normal;
    color: #796E6E;
    span {
        margin-left: 4px;
        cursor: pointer;
        padding: 8px;
        color: #796E6E;
    }
}