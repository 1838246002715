.account-root {
  width: 720px;
  box-sizing: border-box;
  .account-container {
    overflow: hidden;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .information-input-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .MuiFormControl-root{
      margin-left: 32px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 40px;
      width: 180px;
    }
    .bottom-select{
      display: flex;
      align-items: center;
    }
    .bottom-button {
      width: 160px;
      align-self: flex-end;
      height: 40px;
      margin-top: 24px;
    }
  }
}