.landing-page-container {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.landing-page-root {
    width: 900px;
    display: flex;
    justify-content: space-between;
    height: 606px;
}
.image-container {
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
    h3 {
        position: absolute;
        width: 368px;
        font-size: 24px;
        font-family: "Roboto";
        color: white;
        font-style: normal;
        font-weight: normal;
        top: 111px;
        left: 103px;
    }
    h4 {
        position: absolute;
        color: white;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
        width: 400px;
        font-size: 18px;
        line-height: 21px;
        top: 192px;
        left: 102px;
    }
}
.login-container {
    width: 300px;
    height: 365px;
    padding: 40px;
    z-index: 2;
    background: white;
    align-self: center;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        margin: 0;
        #title {
            color: #434448;
            font-size: 18px;
        }
    }
    a: {
        color: #47567b;
        cursor: pointer;
        text-decoration: none;
    }
}

.login-error-filed {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: red;
}
