.help-dialog-title {
    padding: 0 !important;
        margin: 0 !important;
    h2 {
        padding: 0 !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
        }
        img {
            cursor: pointer;
        }
    }
}
.help-dialog-body {
    margin-top: 32px;
    width: 446px;
    padding: 0 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
}
.help-dialog-actions {
    padding: 0 !important;
    button {
        margin-top: 24px;
        width: 90px;
}
}
