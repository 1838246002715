html {
  overflow-y: overlay;
  -webkit-overflow-scrolling: overlay;
}
body {
  min-height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  background: #E5E5E5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.MuiAutocomplete-option{
  padding: 0 !important;
  margin: 0 !important;
}

.Mui-checked {
  color: #796E6E !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  min-height: 40px;
  padding: 0px 12px;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  display: none;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  padding: 40px;
  margin: 0px;
  width: 446px;
}
.MuiPaper-root.MuiAlert-root.MuiAlert-filledError.MuiPaper-elevation0 {
  display: flex;
  align-items: center;
}
select.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  background: none;
  padding: 8px;
}
.MuiAlert-message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type="number"] {
   -moz-appearance: textfield;
}
.breaking-line {
  width: 200%;
  border-top: 1px solid #EBEBEB;
  margin-left: -50%;
}
a {
  text-decoration: none;
}
.MuiPaper-root.MuiAlert-root.MuiAlert-filledSuccess.MuiPaper-elevation0 {
  background-color: #434448;
  padding-right: 24px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.small36-input-field input{
  height: 36px !important;
}

@supports (-moz-appearance:none) {
  html {
    overflow-y: scroll;
    overflow-x: hidden;
   } 
}
.ql-container {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 19px;
  color: #505154 !important;
}
.ql-container p, .ql-container p > span {
  font-size: 16px;
  font-style: normal;
  font-family: Roboto;
  font-weight: normal;
  line-height: 19px;
  color: #505154 !important;
}