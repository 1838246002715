.checkbox {
    color: #d8cece;
    padding: 8px;
}
.checkbox-label {
    color: #796e6e;
    font-size: 14px;
    font-family: "Roboto";
    line-height: 16px;
}
