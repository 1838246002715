.navbar-root {
  height: 56px !important;
  position: fixed;
  background: #ffffff;
  z-index: 150;
}
.navbar-container {
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0 10px;
}
.navbar-account-icons {
  display: flex;
  button {
    padding: 6px 0px !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 56px;
    margin-top: -10px;
    width: 50px;
    &.bordered {
      border-bottom: 1px solid #434448;
    }
  }
}
.navbar-controls {
  margin-left: 220px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  .dropdown-button {
    margin-top: 1px;
    text-transform: none;
    height: 100%;
    padding: 0px 20px 0px 20px;
    border-bottom-left-radius: 0;
    display: flex;
    border-bottom-right-radius: 0;
    align-items: center;
    border-bottom: 1px solid #ff000000;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #796e6e;
  }
  .nav-button {
    border-bottom: 1px solid #ff000000;
    height: 100%;
    padding: 0px 20px 0px 20px;
    border-bottom-left-radius: 0;
    display: flex;
    border-bottom-right-radius: 0;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #796e6e;
  }
  .selected {
    color: #434448;
    border-bottom: 1px solid #434448 !important;
  }
}
