.text-input-container {
    display: flex;
    flex-direction: column;        
    input {
        background: #FFFFFF;
        border: 1px solid #D8CECE;
        box-sizing: border-box;
        border-radius: 4px;
        color: #505154;
        height: 40px;
        margin-top: 8px;
    }
}